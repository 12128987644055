<template>
    <div id="pending-dashboard">
        <div class="pt-container">
            <div class="pt-header">
                <div class="pt-title">
                    Pendências
                    <div id="help-icon" class="no-print">
                        <v-help-circle class="icon stroke" />
                    </div>
                    <b-tooltip target="help-icon" placement="bottom">
                        Crie e gerencie contas a receber, visualize datas, categorias, descrições e valores.
                        Selecione as contas que deseja e clique no botão “nova entrada” para gerar nova conta a receber.
                    </b-tooltip>
                </div>

                <div class="gap-action-buttons no-print">
                    <CustomButton :disabled="disabledNewTissGuide" @click="showModalCreateGuide" v-can="'GerPenPen2'">
                        <TissIcon />
                        Criar nova guia
                    </CustomButton>
                    <CustomButton :disabled="isButtonDisabledAccountsReceivable" @click="createBill" 
                        v-can="'GerPenPen3'">
                        <FinancialIcon />
                        Nova conta a receber
                    </CustomButton>
                    <Refresh class="reload-icon" @click="reload" />

                </div>
            </div>

            <PendingDashboardFilter @changeFilter="debounceGetPatients" />
        </div>

        <div class="content">
            <PendingDashboardTable :data="pendingItems" :loading="loading" :checkedItems="checkedItems"
                @checked="changeCheckedItems" @discardPendingItem="discardPendingItem" />
        </div>


        <div class="amount-position" v-if="!loading">
            <div>
                <p> Valor total
                    <span>
                        R${{ parseNumberToMoney(getTotalAMount()).trim().split('$')[1] }}
                    </span>
                </p>
            </div>
            <div>
                <p> Valor pág. {{ this.page }} de {{ this.last_page }}
                    <span>
                        R${{ parseNumberToMoney(getPageAmount()).trim().split('$')[1] }}
                    </span>
                </p>
            </div>
        </div>

        <div class="pagination-position no-print">
            <b-pagination size="sm" :value="page" :total-rows="count" :per-page="limit" first-number
                @input="onChangePage" last-number />
        </div>

        <NewTissGuideModalVue :patient="patient" :healthPlan="healthPlan" :items="checkedItems"
            :disabledHealthPlan="canEditHealthPlan" @createGuide="createGuide"
            @setClinicHealthPlanToPending="setClinicHealthPlan"/>

        <NewSusGuideModalVue :patient="patient" :healthPlan="healthPlan" :items="checkedItems"
            :disabledHealthPlan="canEditHealthPlan" @createGuideSus="createGuideSus"
            @setClinicHealthPlanToPending="setClinicHealthPlan" :closeModal="closeSusGuideModal"/>

        <BillItemsModal :billId="billId" :patient="patient" :appointmentDate="appointmentDate"
            :checkedItems="checkedItems" @reload="reload" @clearItems="clearItems" @hidden="billId = null"
            @issueInvoice="issueInvoice" :creditPatientCategory="categorySelected" />
        <NewInvoiceModal />
        <ResumeInvoiceModal />
        <TissGuidesModalsContainer :patient="patient" :tissGuide="tissGuide" :susGuide="susGuide"
            :clinicHealthPlan="clinicHealthPlan" :getLastTissGuide="getLastTissGuide" :deleteTissGuide="deleteTissGuide"
            :clearTissGuide="clearTissGuide"
            :required_fields="clinicHealthPlan?.invoicing_tiss_setting?.tiss_information.tiss_required_custom_field"
            :getClinicHealthPlan="(invoicingTissSettingId) => { }" :setTissGuide="setTissGuide" :getGuide="getGuide"
            :checkedItems="checkedItems" />

    </div>


</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper';
import { handleGuideUpdate } from '@/utils/invoicingTissHelper';
import { debounce } from 'lodash';

export default {
    name: 'pending-dashboard-tab',
    components: {
        Refresh: () => import('@/assets/icons/loading.svg'),
        'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
        PendingDashboardTable: () => import('@/modules/financial/components/PendingDashboardTable'),
        TissIcon: () => import('@/assets/icons/tiss-icon.svg'),
        FinancialIcon: () => import('@/assets/icons/financial-icon.svg'),
        CustomButton: () => import('@/components/AppointmentModal/CustomButton.vue'),
        NewTissGuideModalVue: () => import('@/components/Tiss/Modal/NewTissGuideModal2'),
        NewSusGuideModalVue: () => import('@/components/Sus/Modal/NewSusGuideModal'),
        BillItemsModal: () => import('@/modules/financial/modals/BillItemsModal'),
        TissGuidesModalsContainer: () => import('@/layouts/InvoicingTiss/TissGuide/TissGuidesModalsContainer.vue'),
        NewInvoiceModal: () => import('@/modules/nfe/modals/NewInvoiceModal'),
        ResumeInvoiceModal: () => import('@/modules/nfe/modals/ResumeInvoiceModal'),
        PendingDashboardFilter: () => import('@/modules/financial/components/PendingDashboardFilter')
    },
    data() {
        const clinic = JSON.parse(localStorage.getItem('clinic'));
        return {
            loading: false,
            selected: [],
            billToReceive: null,
            billId: null,
            appointmentDate: null,
            categorySelected: null,
            tissGuide: null,
            susGuide: null,
            clinicHealthPlan: null,
            tissRequiredCustomField: null,

            query: '',
            page: 1,
            limit: 0,
            count: 0,
            last_page: 1,
            clinicId: clinic.id,
            pendingItems: [],
            status_payment: null,
            start: null,
            end: null,
            beneficiary: null,
            professional: null,
            creators: [],
            creator_id: null,
            patient: null,
            patients: [],
            payment_method: null,
            payment_methods: [],
            healthPlan: null,
            bank_account: null,
            bank_accounts: [],
            chevronState: false,
            checkedItems: [],
            sumInstallmentAmountOfPage: 0,
            sumAllInstallmentAmount: 0,
            search: '',
            chevronRotate: false,
            period: '',
            debounceGetPatients: debounce(this.getPendingAppointmentItems, 500),
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
                }
            },
            filter: {
                healthPlan: null,
                professional: null,
                start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
                query: '',
                status: [],
                item: [],
                patient: null,
                itemType: null
            }
        };
    },
    computed: {
        canEditHealthPlan() {
            return this.checkedItems.some(item => item.invoicing_tiss_active);
        },
        isButtonDisabledAccountsReceivable() {
            return !this.checkedItems.length ||
                this.checkedItems.some(item => item.invoicing_tiss_active) ||
                this.checkedItems.some(item => item.invoicing_sus_active);
        },
        disabledNewTissGuide() {
            return (!this.checkedItems.length ||
                !this.checkedItems.some(item => item)) ||
                !this.checkedItems.some(item => item.invoicing_tiss_active) &&
                !this.checkedItems.some(item => item.invoicing_sus_active);
        }
    },

    methods: {
        parseNumberToMoney,
        handlePeriod({ start, end }) {
            this.filter.start = start;
            this.filter.end = end;
        },
        getTotalAMount() {
            return this.pendingItems[0]?.total_value;
        },
        getPageAmount() {
            const items = this.pendingItems;
            return items.reduce((acc, item) => {
                let value = 0;
                if (item.health_plan?.clinic_health_plan[0]?.invoicing_tiss_active !== 1) {
                    console.log('ITEM =>', item.item.name)
                    value = item.value;
                }
                return acc + value;
            }, 0);
        },
        resetChevronState() {
            if (this.chevronState === true) {
                const chevron = document.getElementById('chevron');
                chevron.classList.toggle('chevron-active');
                this.chevronState = false;
            }
        },
        discardPendingItem(itemId) {
            const index = this.pendingItems.findIndex(el => el.id === itemId);
            const copy = Object.assign({}, this.pendingItems[index]);
            this.pendingItems.splice(index, 1);
            this.api.updateAppointmentItem(itemId, { solved: true })
                .catch(err => {
                    this.$toast.error(err.message);
                    this.pendingItems.splice(index, 0, copy);
                });
        },
        async getPendingAppointmentItems(filter) {
            try {
                this.loading = true;
                this.filter = filter
                const filters = {
                    ...filter,
                    status: filter.status?.map(status => status.value),
                }
                const { data } = await this.api.getPendingAppointmentItems(this.page, this.clinicId, null, filters);

                if (!data) {
                    this.$bvToast.error('Erro ao buscar os itens pendentes');
                }

                this.checkedItems = [];
                this.patient = null;
                this.clinicHealthPlan = null;
                this.pendingItems = data.data;
                this.count = data.total;
                this.limit = data.per_page;
                this.page = data.current_page;
                this.last_page = data.last_page;

            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        createBill() {
            this.$bvModal.show('bill-items-modal');
        },
        showModalCreateGuide() {
            this.healthPlan = {
                ...this.checkedItems[0]?.health_plan,
                label: this.checkedItems[0]?.health_plan?.fantasy_name,
            };

            if (this.checkedItems.some(item => item.invoicing_tiss_active)) {
                this.$bvModal.show('new-tiss-guide-modal');
            } else {
                this.$bvModal.show('new-sus-guide-modal');
            }
        },
        setClinicHealthPlan(clinicHealthPlan) {
            this.$emit('setClinicHealthPlanToContent', clinicHealthPlan);
        },
        closeSusGuideModal() {
            this.$bvModal.hide('new-sus-guide-modal');
        },
        changeCheckedItems(checkedItems) {
            this.patient = checkedItems[0]?.appointment?.patient; 
            this.checkedItems = checkedItems;
        },
        createGuide({ tissGuide, clinicHealthPlan }) {
            this.clinicHealthPlan = clinicHealthPlan;
            this.tissGuide = { ...tissGuide }
        },
        createGuideSus({ susGuide, clinicHealthPlan }) {
            this.susGuide = susGuide;
            this.clinicHealthPlan = clinicHealthPlan;
            this.patient = susGuide.patient;
        },
        clearItems() {
            this.clinicHealthPlan = null
            this.patient = null;
            this.checkedItems = [];
        },
        async getGuide(tissGuideId) {
            if (typeof tissGuideId === 'object') tissGuideId = tissGuideId.id;
            const isLoading = this.$loading.show();
            try {
                const response = await this.api.getTissGuideById(tissGuideId);
                this.tissGuide = response.data;
                await this.getClinicHealthPlan(this.tissGuide.invoicing_tiss_setting_id);
                this.$bvModal.show(handleGuideUpdate(this.tissGuide.guide_type));
            } catch (error) {
                console.error(error)
                this.$toast.error(error.message);
            } finally {
                isLoading.hide();
            }
        },
        async getClinicHealthPlan(invoicingTissSettingId) {
            if (!this.clinicId || !invoicingTissSettingId) return;
            try {
                const response = await this.api.findByClinicIdAndInvoicingTissSettingId(
                    this.clinicId,
                    invoicingTissSettingId
                );
                this.clinicHealthPlan = response.data;
                this.tissRequiredCustomField =
                    this.clinicHealthPlan.invoicing_tiss_setting?.tiss_information.tiss_required_custom_field;
            } catch (error) {
                console.error(error)
                this.$toast.error(error.message);
            }
        },
        setTissGuide(tissGuide) {
            this.tissGuide = null;
            this.tissGuide = tissGuide;
        },
        async showModal(historyItem) {
            if (historyItem.type === 'bill') {
                this.billId = null;
                this.billId = historyItem.bill_id;
                this.$bvModal.show('bill-items-modal');
            } else if (historyItem.type === 'tissGuideItem') {
                await this.getGuide(historyItem.tiss_guide_id);
            } else if (historyItem.type === 'susGuideItem') {
                const isLoading = this.$loading.show();
                const { data } = await this.api.getSusGuideById(historyItem.guide_id);
                this.susGuide = data;
                if (historyItem.guide_type === "Guia BPA") {
                    this.$bvModal.show('bpa-sus-guide-modal');
                } else if (historyItem.guide_type === "Guia APAC") {
                    this.$bvModal.show('apac-sus-guide-modal');
                } else {
                    this.$bvModal.show('aih-sus-guide-modal');
                }

                isLoading.hide();
            }
        },
        reload() {
            this.getPendingAppointmentItems(this.filter);
        },
        async findByGuideIdReport(id) {
            const isLoading = this.$loading.show();
            try {
                const { data } = await this.api.findByGuideIdReport(id);
                return data;
            } catch (error) {
                console.error(error)
                this.$toast.error(error.message);
            } finally {
                isLoading.hide();
            }
        },
        async deleteTissGuide(tissGuide, decrease = false) {
            const isLoading = this.$loading.show();
            try {
                await this.api.deleteTissGuide(tissGuide?.id, decrease);
                this.$toast.success('Guia removida com sucesso!');
                this.reload();
            } catch (error) {
                console.error(error)
                this.$toast.error(error.message);
            } finally {
                isLoading.hide();
            }
        },
        async getLastTissGuide(data) {
            if (data.isSus) {
                this.changeCheckedItems([]);
                this.reload();
                return;
            }

            if (data.other_expenses) {
                this.reload();
                return;
            }
            if (data?.id && !data.guide_id) {
                const tissGuide = await this.findByGuideIdReport(
                    data.id
                );
                if (!data?.notReopen) this.getGuide(tissGuide);
            }
        },
        clearTissGuide() {
            this.tissGuide = {};
            this.clearItems();
            this.reload();
        },
        onChangePage(page) {
            this.page = page;
            this.getPendingAppointmentItems(this.filter);
        },
        issueInvoice() {
            this.$bvModal.show('new-invoice-modal');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/dx-datepicker.scss';

.gap-action-buttons {
    display: flex;
    align-items: center;
    gap: 15px;
}

.reload-icon {
    width: 24px;
    height: 24px;
    stroke: var(--dark-blue);
    cursor: pointer;

    :hover {
        stroke: var(--type-placeholder);
    }
}

.pt-container {
    padding: 20px 10px;

    .pt-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0 20px;

        .wh-button {
            color: var(--blue-500);
            height: 40px !important;
            font-weight: 700 !important;
        }

        .multiselect {
            width: 198px;
            border: 2px solid var(--blue-500);
            border-radius: 8px;

            .multiselect__tags {
                min-height: 35px !important;

                .multiselect__single {
                    font-weight: 700 !important;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--blue-500);
                }
            }
        }

        .chevron {
            width: 24px;
            height: 24px;
            stroke: var(--blue-500);
            cursor: pointer;

            svg {
                stroke: var(--blue-500);
            }
        }

        .account-options {
            color: var(--blue-500);
            font-weight: 700 !important;
        }
    }

    .pt-title {
        display: inline-flex;
        align-items: center;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;

        .icon {
            height: 24px;
            width: 24px;
            margin-left: 5px;
            stroke: var(--neutral-500);
        }
    }

    .icon {
        width: 25px;
        height: 25px;

    }

    @media (min-width: 1440px) {
        .icon {
            margin-left: 5px;
        }
    }

    .pagination-position {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;
    }

    label {
        width: 100%;
        font-family: 'Nunito Sans';
        font-weight: 700;
        font-size: 16px;
        color: var(--type-active);
        text-align: left !important;
        color: var(--dark-blue);
        margin-bottom: 4px;
    }

    .content {
        margin: 0 auto;
    }

    .grid-table {
        width: 100%;
        height: min-content;
    }

    tr {
        width: 100%;
        vertical-align: top;
    }

    td {
        padding: 5px;
    }

    .small-card {
        width: 25% !important;
    }

    .large-card {
        width: 50% !important;
    }

    .medium-card {
        width: 25% !important;
    }

    .noDataCard {
        display: flex;
        justify-content: center;
        height: 100%;
        border-radius: 8px;
    }

    .dashboard-bottom {
        padding: 5px;

        .dashboard-bottom-container-extern {
            height: auto;
            width: 100%;
            border: 1px solid #D9DFF2;
            border-radius: 8px;

            .dashboard-bottom-container-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 24px 24px 0;

                .dashboard-bottom-container-title {
                    font-family: 'Red Hat Display';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%;
                    color: var(--dark-blue);
                }

                .dx-datepicker {
                    caret-color: transparent;

                    #chevron {
                        cursor: pointer;
                        transition: all 500ms;
                    }

                    .datepicker-chevrondown-icon {
                        fill: transparent;
                        stroke: var(--blue-500);
                        height: 24px;
                        width: 24px;
                    }

                    .chevron-active {
                        transform: rotate(-180deg);
                    }
                }
            }

            .dashboard-bottom-container-intern {
                display: grid;
                grid-template-columns: 2.2fr 1fr;
                height: auto;
                width: 100%;
                padding: 24px;
                gap: 16px;

                .week-total-values {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 16px;
                }
            }
        }
    }

    .cash-flow-wrapper {
        padding: 5px;
    }
}

.input-wrapper {
    position: relative;
}

.input-wrapper .icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.input-wrapper .form-control {
    padding-left: 35px;
}

.amount-position {
    display: flex;
    flex-direction: row-reverse;

    div {
        padding: 8px 16px;
        background: var(--blue-100);
        border-radius: 8px;
        margin-left: 10px;
        justify-content: center;

        p {
            font-size: 16px;
            line-height: 150%;
            color: var(--type-active);

            span {
                font-weight: 700;
                color: var(--blue-500);
            }
        }
    }
}
</style>
